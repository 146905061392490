<template>
    <div class="tablePart flex-between">
        <div class="tableLeft">
            <div class="topPart flex-between">
                <div class="topItem">
                    <div class="label">考评户次</div>
                    <div class="line"></div>
                    <div class="echart">
                        <CircleEchart
                            id="leftEchart"
                            dw="户次"
                            :listAry="leftEchartPart.list"
                            ref="echartLeft"
                        ></CircleEchart>
                    </div>
                </div>
                <div class="topItem">
                    <div class="flex-y-center">
                        <div class="label">考评结果</div>
                        <el-date-picker
                            v-model="timeKp"
                            class="timePart"
                            align="left"
                            type="month"
                            value-format="yyyy-MM"
                            format="yyyy-MM"
                            @change="getEchartRight()"
                        >
                        </el-date-picker>
                    </div>

                    <div class="line"></div>
                    <div class="echart">
                        <CircleEchart
                            id="rightEchart"
                            dw="次"
                            :listAry="rightEchartPart.list"
                            ref="echartRight"
                        ></CircleEchart>
                    </div>
                </div>
            </div>
            <div class="centerPart">
                <div class="label" style="width: 130px">村民小组排名</div>
                <div class="line"></div>
                <div class="tablePart flex-between">
                    <div class="tableItem">
                        <ComTable
                            :listAry="cmxzpmPart.tableListLeft"
                            :legendList="cmxzpmPart.legendList"
                            :maxHeight="cmxzpmPart.maxHeight"
                        ></ComTable>
                    </div>
                    <div class="tableItem">
                        <ComTable
                            :listAry="cmxzpmPart.tableListRight"
                            :legendList="cmxzpmPart.legendList"
                            :maxHeight="cmxzpmPart.maxHeight"
                        ></ComTable>
                    </div>
                </div>
            </div>
            <div class="bottomPart">
                <div class="label">村民排名</div>
                <div class="line"></div>
                <div class="tablePart flex-between">
                    <div class="tableItem">
                        <ComTable
                            :listAry="cmpmPart.tableListLeft"
                            :legendList="cmpmPart.legendList"
                            :maxHeight="cmpmPart.maxHeight"
                        ></ComTable>
                    </div>
                    <div class="tableItem">
                        <ComTable
                            :listAry="cmpmPart.tableListRight"
                            :legendList="cmpmPart.legendList"
                            :maxHeight="cmpmPart.maxHeight"
                        ></ComTable>
                    </div>
                </div>
            </div>
        </div>
        <div class="tableRight">
            <div class="titlePart flex-y-center">
                <div class="label">扣分项</div>

                <el-select
                    v-model="cmxzId"
                    placeholder="请选择村民小组"
                    clearable
                    class="time"
                    @change="getKfxList()"
                    filterable
                >
                    <el-option
                        v-for="(item, index) in cmxzList"
                        :key="index"
                        :label="item.MC"
                        :value="item.BM"
                    >
                    </el-option>
                </el-select>
            </div>
            <div class="line"></div>
            <div class="tablePart">
                <KfxTable ref="kfxTable"></KfxTable>
            </div>
        </div>
    </div>
</template>
<script>
import * as TYPES from "@store/modules/mutation-type.js";
import { mapActions, mapMutations, mapGetters } from "vuex";
import CircleEchart from "./echartPart/CircleEchart.vue"; //考评详情
import ComTable from "./echartPart/ComTable.vue"; //一般 表格
import KfxTable from "./echartPart/KfxTable.vue"; //扣分项 表格
export default {
    name: "",
    components: {
        CircleEchart,
        ComTable,
        KfxTable,
    },
    data() {
        return {
            leftEchartPart: {
                list: [
                    {
                        name: "优秀奖",
                        color: "rgba(126,207,81,1)",
                        num: 0,
                        per: 0,
                    },
                    {
                        name: "良好奖",
                        color: "rgba(238,203,95,1)",
                        num: 0,
                        per: 0,
                    },
                    {
                        name: "合格奖",
                        color: "rgba(227,147,93,1)",
                        num: 0,
                        per: 0,
                    },
                    {
                        name: "不合格",
                        color: "rgba(225,103,87,1)",
                        num: 0,
                        per: 0,
                    },
                ],
            },
            rightEchartPart: {
                list: [
                    {
                        name: "优秀奖",
                        color: "rgba(126,207,81,1)",
                        num: 0,
                        per: 0,
                    },
                    {
                        name: "良好奖",
                        color: "rgba(238,203,95,1)",
                        num: 0,
                        per: 0,
                    },
                    {
                        name: "合格奖",
                        color: "rgba(227,147,93,1)",
                        num: 0,
                        per: 0,
                    },
                    {
                        name: "不合格",
                        color: "rgba(225,103,87,1)",
                        num: 0,
                        per: 0,
                    },
                ],
            },
            timeKp: this.common.getTimeYm(), //考评时间
            cmxzId: "",
            cmxzList: [],
            kfxList: [], //扣分项
            // 村民小组排名
            cmxzpmPart: {
                maxHeight: "200",
                tableListLeft: [],
                tableListRight: [],
                legendList: [
                    {
                        name: "排名",
                        prop: "PM",
                    },
                    {
                        name: "村民小组",
                        prop: "XZZ",
                    },
                    {
                        name: "平均分",
                        prop: "PJZ",
                    },
                ],
            },
            // 村民排名
            cmpmPart: {
                maxHeight: "180",
                tableListLeft: [],
                tableListRight: [],
                legendList: [
                    {
                        name: "排名",
                        prop: "PM",
                        width: 50,
                    },
                    {
                        name: "优秀奖次数",
                        prop: "CS",
                    },
                    {
                        name: "平均分",
                        prop: "PJZ",
                        width: 70,
                    },
                    {
                        name: "民居编号",
                        prop: "MJBH",
                        width: 80,
                    },
                    {
                        name: "村民小组",
                        prop: "XZZ",
                        width: 60,
                    },
                ],
            },
        };
    },
    computed: {
        ...mapGetters({}),
    },
    watch: {},
    created() {},
    mounted() {
        this.getEchartLeft();
        this.getEchartRight();
        this.getXzList();
        this.getKfxList();
        this.getCmxzpmList();
        this.getCmpmList();
    },
    methods: {
        ...mapMutations({}),
        ...mapActions([
            "GetTjKphcAndKpjg",
            "GetXZQHByCode",
            "GetTjKfx",
            "GetTjCmxzPm",
            "GetTjMjPm",
        ]),
        async getEchartLeft() {
            let result = await this.GetTjKphcAndKpjg({
                sj: "",
            });

            this.leftEchartPart.list[0].num = result.RESULTVALUE.YXNUM; //优秀奖
            this.leftEchartPart.list[1].num = result.RESULTVALUE.LHNUM; //良好奖
            this.leftEchartPart.list[2].num = result.RESULTVALUE.HGNUM; //合格奖
            this.leftEchartPart.list[3].num = result.RESULTVALUE.BHGNUM; //不合格奖

            this.leftEchartPart.list[0].per = result.RESULTVALUE.YX; //优秀奖
            this.leftEchartPart.list[1].per = result.RESULTVALUE.LH; //良好奖
            this.leftEchartPart.list[2].per = result.RESULTVALUE.HG; //合格奖
            this.leftEchartPart.list[3].per = result.RESULTVALUE.BHG; //不合格奖

            this.$refs.echartLeft.drawEchart();
        },
        async getEchartRight() {
            let result = await this.GetTjKphcAndKpjg({
                sj: this.timeKp ? this.timeKp : "",
            });

            this.rightEchartPart.list[0].num = result.RESULTVALUE.YXNUM; //优秀奖
            this.rightEchartPart.list[1].num = result.RESULTVALUE.LHNUM; //良好奖
            this.rightEchartPart.list[2].num = result.RESULTVALUE.HGNUM; //合格奖
            this.rightEchartPart.list[3].num = result.RESULTVALUE.BHGNUM; //不合格奖

            this.rightEchartPart.list[0].per = result.RESULTVALUE.YX; //优秀奖
            this.rightEchartPart.list[1].per = result.RESULTVALUE.LH; //良好奖
            this.rightEchartPart.list[2].per = result.RESULTVALUE.HG; //合格奖
            this.rightEchartPart.list[3].per = result.RESULTVALUE.BHG; //不合格奖

            this.$refs.echartRight.drawEchart();
        },
        // 获取 小组 列表
        async getXzList() {
            let result = await this.GetXZQHByCode({
                code: "",
                userid: "",
                lx: 1, //0 存 1组
            });
            // console.log("获取 小组 列表", result);
            this.cmxzList = result.RESULTVALUE;
        },
        // 获取 扣分项 列表
        async getKfxList() {
            let result = await this.GetTjKfx({
                cmxzbm: this.cmxzId ? this.cmxzId : "",
            });
            this.$refs.kfxTable.formatData(result.RESULTVALUE);
        },
        // 村民小组排名
        async getCmxzpmList() {
            let result = await this.GetTjCmxzPm({});
            result.RESULTVALUE.map((item, index) => {
                item.PM = index + 1;
            });
            this.cmxzpmPart.tableListLeft = result.RESULTVALUE.slice(0, 6);
            this.cmxzpmPart.tableListRight = result.RESULTVALUE.slice(6);
        },
        // 村民排名
        async getCmpmList() {
            let result = await this.GetTjMjPm({});
            this.cmpmPart.tableListLeft = result.RESULTVALUE.TOP10;
            this.cmpmPart.tableListRight = result.RESULTVALUE.DESCTOP10;
        },
    },
};
</script>
<style scoped lang="scss">
.tablePart {
    width: 100%;
    .tableLeft {
        width: 49%;
        .topPart {
            width: 100%;
            height: 230px;
            .topItem {
                width: 48%;
                height: 100%;
                .timePart {
                    width: 120px;
                    margin-left: 10px;
                }
                .echart {
                    width: 100%;
                    height: 170px;
                }
            }
        }
        .centerPart {
            width: 100%;
            height: 230px;
            .tablePart {
                .tableItem {
                    width: 49%;
                    height: 100%;
                }
            }
        }
        .bottomPart {
            width: 100%;
            height: 230px;
            .tablePart {
                .tableItem {
                    width: 49%;
                    height: 100%;
                }
            }
        }
    }
    .tableRight {
        width: 49%;
        .titlePart {
            .time {
                margin-left: 10px;
            }
        }
        .tablePart {
            margin-top: 20px;
        }
    }
    .label {
        width: 96px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        background: rgba(68, 169, 127, 0.1);
        color: rgba(68, 68, 68, 1);
        font-size: 18px;
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
    }
    .line {
        width: 100%;
        height: 2px;
        background: rgba(68, 169, 127, 0.2);
    }
}
</style>
