<template>
    <div class="kpTable">
        <el-table
            row-key="ID"
            :data="tableList"
            style="width: 100%"
            max-height="630"
            :span-method="objectSpanMethod"
        >
            <el-table-column
                type="index"
                label="序号"
                width="55"
            ></el-table-column>
            <el-table-column
                prop="PFXM"
                label="评分项目"
                align="center"
                width="90"
            ></el-table-column>

            <el-table-column prop="DFX" label="得分项" align="center">
                <template slot-scope="scope">
                    <div
                        :style="{
                            color:
                                parseInt(scope.row.PJF) < parseInt(scope.row.FZ)
                                    ? 'red'
                                    : '',
                        }"
                    >
                        {{ scope.row.DFX }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column
                prop="FZ"
                label="得分值"
                align="center"
                width="90"
            ></el-table-column>
            <el-table-column
                prop="PJF"
                label="平均分"
                align="center"
                width="90"
            ></el-table-column>
        </el-table>
    </div>
</template>
<script>
import { mapActions, mapMutations, mapGetters } from "vuex";
export default {
    name: "",
    components: {},
    data() {
        return {
            tableListIdMin: [], //合并表格max
            tableListColumn: [], //合并表格
            tableList: [],
        };
    },
    computed: {
        ...mapGetters({}),
    },
    props: {},
    watch: {},
    created() {},
    mounted() {},
    methods: {
        ...mapMutations({}),
        ...mapActions([]),
        formatData(list) {
            this.tableListId = [];
            this.tableListColumn = [];
            list.map((item) => {
                if (!this.tableListId.includes(item.PFXMID)) {
                    this.tableListId.push(item.PFXMID);
                    let obj = {
                        id: item.PFXMID,
                        num: 1,
                        name: item.PFXM,
                        idChild: [item.DFXID],
                    };
                    this.tableListColumn.push(obj);
                } else {
                    this.tableListColumn.map((itemInner) => {
                        if (itemInner.id == item.PFXMID) {
                            itemInner.num++;
                            itemInner.idChild.push(item.DFXID);
                        }
                    });
                }
            });
            list.map((item, index) => {
                item.ID = index;
                this.tableListColumn.map((itemInner) => {
                    itemInner.idMin = String(Math.min(...itemInner.idChild));
                    if (itemInner.id == item.PFXMID) {
                        item.num = itemInner.num;
                    }
                });
            });
            this.tableListIdMin = [];
            this.tableListColumn.map((item) => {
                this.tableListIdMin.push(String(item.idMin));
            });
            this.tableList = list;
        },
        objectSpanMethod({ row, column, rowIndex, columnIndex }) {
            if (columnIndex === 1) {
                if (this.tableListIdMin.includes(row.DFXID)) {
                    return {
                        rowspan: row.num,
                        colspan: 1,
                    };
                } else {
                    return {
                        rowspan: 0,
                        colspan: 0,
                    };
                }
            }
        },
    },
};
</script>
<style scoped lang="scss">
</style>
