<template>
    <div class="tableCompPart">
        <div class="timePart flex-end">
            <el-date-picker
                v-model="startTime"
                class="searchItem"
                align="left"
                type="month"
                value-format="yyyy-MM"
                format="yyyy-MM"
                size="mini"
                @change="getMainInfo(detail)"
            >
            </el-date-picker>
            <el-date-picker
                v-model="endTime"
                class="searchItem"
                align="left"
                type="month"
                value-format="yyyy-MM"
                format="yyyy-MM"
                size="mini"
                @change="getMainInfo(detail)"
            >
            </el-date-picker>
        </div>
        <div class="topPart flex">
            <div class="leftEchartPart">
                <CircleEchart
                    id="leftEchart"
                    dw="次"
                    :listAry="leftEchartList"
                    ref="echartLeft"
                ></CircleEchart>
            </div>
            <div class="rightEchart flex-1">
                <LineEchart :listAry="timeList"></LineEchart>
            </div>
        </div>
        <div class="bottomPart flex scrollbar-hidden">
            <div class="timeListPart scrollbar-hidden">
                <div
                    v-for="(item, index) in timeList"
                    :key="index + 'asdf'"
                    class="timeItem flex-end pointer"
                    @click="getTimeItem(item, index)"
                >
                    <div
                        class="time"
                        :class="[timeActiveNum == item.SJ ? 'timeActive' : '']"
                    >
                        {{ item.SJ }}
                    </div>
                    <img
                        :src="
                            timeActiveNum == item.SJ
                                ? require('@image/culture_hourse/evaluate/arrowActive.png')
                                : require('@image/culture_hourse/evaluate/arrowDefault.png')
                        "
                        class="img"
                    />
                </div>
            </div>
            <div class="tablePart flex-1">
                <div class="scorePart">
                    <div class="flex averagePart">
                        <div class="name">平均分：</div>
                        <div class="num">{{ average }}</div>
                    </div>
                    <div class="centList flex flex-wrap">
                        <div
                            v-for="(item, index) in centList"
                            :key="index"
                            class="flex centItem"
                        >
                            <div>{{ item.NAME }}:</div>
                            <div>{{ item.FS }}</div>
                        </div>
                    </div>
                </div>
                <div class="kpTable pointer flex-end" @click="exportkpTable()">
                    导出考评表
                </div>
                <!--表单 -->
                <div class="kpTable">
                    <el-table
                        row-key="ID"
                        :data="tableList"
                        style="width: 100%"
                        max-height="220"
                        :span-method="objectSpanMethod"
                    >
                        <el-table-column
                            type="index"
                            label="序号"
                            width="55"
                        ></el-table-column>
                        <el-table-column
                            prop="PFXM"
                            label="评分项目"
                            align="center"
                            width="90"
                        ></el-table-column>
                        <el-table-column
                            prop="DFX"
                            label="得分项"
                            align="center"
                        >
                            <template slot-scope="scope">
                                <div
                                    :style="{
                                        color:
                                            parseInt(scope.row.PJF) <
                                            parseInt(scope.row.DFZ)
                                                ? 'red'
                                                : '',
                                    }"
                                >
                                    {{ scope.row.DFX }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="DFZ"
                            label="得分值"
                            align="center"
                        ></el-table-column>
                        <el-table-column
                            v-for="(item, index) in centList"
                            :key="index"
                            :prop="item.NAME.toUpperCase()"
                            :label="item.NAME + '评分'"
                            align="center"
                        ></el-table-column>
                        <el-table-column
                            prop="PJF"
                            label="平均分"
                            align="center"
                        ></el-table-column>
                    </el-table>
                </div>
                <!-- 轮播图 -->
                <div class="carouselPart flex-y-center">
                    <img
                        :src="
                            require('@image/culture_hourse/evaluate/triangleLeft.png')
                        "
                        class="img pointer"
                        @click="carouselTurn(-1)"
                        v-if="carouselList.length > 1"
                    />
                    <div class="flex-1 carouselImg">
                        <el-carousel
                            indicator-position="none"
                            arrow="never"
                            :autoplay="false"
                            height="120px"
                            ref="carousel"
                        >
                            <el-carousel-item
                                v-for="(item, index) in carouselList"
                                :key="index"
                                class="flex"
                            >
                                <div
                                    class="pointer flex imgList"
                                    v-for="(itemInner, indexInner) in item"
                                    :key="indexInner"
                                >
                                    <el-image
                                        v-viewer
                                        :src="itemInner.LJ"
                                        fit="cover"
                                        class="pointer"
                                        style="
                                            width: 120px;
                                            height: 120px;
                                            border-radius: 10px;
                                        "
                                    >
                                        <div
                                            slot="error"
                                            style="
                                                width: 120px;
                                                height: 120px;
                                                border-radius: 10px;
                                            "
                                        >
                                            <SnNoData
                                                typeNum="1"
                                                width="60px"
                                            ></SnNoData>
                                        </div>
                                    </el-image>

                                    <div class="mask">
                                        <div
                                            class="name pointer"
                                            :title="itemInner.REALNAME"
                                        >
                                            {{ itemInner.REALNAME }}
                                        </div>
                                        <div
                                            class="desc pointer ellipsis"
                                            :title="itemInner.SM"
                                        >
                                            {{ itemInner.SM }}
                                        </div>
                                    </div>
                                </div>
                            </el-carousel-item>
                        </el-carousel>
                    </div>

                    <img
                        :src="
                            require('@image/culture_hourse/evaluate/triangleRight.png')
                        "
                        class="img pointer"
                        @click="carouselTurn(1)"
                        v-if="carouselList.length > 1"
                    />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import * as TYPES from "@store/modules/mutation-type.js";
import { mapActions, mapMutations, mapGetters } from "vuex";
import CircleEchart from "./echartPart/CircleEchart.vue"; //考评详情
import LineEchart from "./echartPart/LineEchart.vue"; //考评记录
export default {
    name: "",
    components: {
        CircleEchart,
        LineEchart,
    },
    data() {
        return {
            startTime: this.common.getTimeYm(), //开始时间
            endTime: this.common.getTimeYm(), //结束时间
            tableList: [], //表格
            tableListId: [], //合并表格
            tableListIdMin: [], //合并表格max
            tableListColumn: [], //合并表格
            detail: null,
            timeList: [],
            timeActiveNum: null,
            carouselList: [],
            carouseNum: 0,
            leftEchartList: [
                {
                    name: "优秀奖",
                    color: "rgba(126,207,81,1)",
                    num: 0,
                    per: 0,
                },
                {
                    name: "良好奖",
                    color: "rgba(238,203,95,1)",
                    num: 0,
                    per: 0,
                },
                {
                    name: "合格奖",
                    color: "rgba(227,147,93,1)",
                    num: 0,
                    per: 0,
                },
                {
                    name: "不合格奖",
                    color: "rgba(225,103,87,1)",
                    num: 0,
                    per: 0,
                },
            ],
            average: 0, //平均分
            centList: [],
            num: 0,
        };
    },
    computed: {},
    props: {},
    watch: {},
    mounted() {
        let year = this.startTime.split("-")[0];
        let month = parseInt(this.startTime.split("-")[1]);
        // 基数
        if (month % 2 != 0) {
            if (month < 10) {
                this.startTime = `${year}-0${month - 1}`;
            } else {
                this.startTime = `${year}-${month - 1}`;
            }
        } else {
            if (month < 10) {
                this.startTime = `${year}-0${month - 2}`;
            } else {
                this.startTime = `${year}-${month - 2}`;
            }
        }
    },
    methods: {
        ...mapActions(["GetWSKPTuInfo", "GetWSKPDataInfo"]),
        async getMainInfo(obj) {
            this.detail = obj;
            let result = await this.GetWSKPTuInfo({
                mjbh: obj.MJBH,
                kssj: this.startTime ? this.startTime : "",
                jssj: this.endTime ? this.endTime : "",
            });
            this.leftEchartList[0].num = result.RESULTVALUE.BL.YX.NUM; //优秀奖
            this.leftEchartList[1].num = result.RESULTVALUE.BL.LH.NUM; //良好奖
            this.leftEchartList[2].num = result.RESULTVALUE.BL.HG.NUM; //合格奖
            this.leftEchartList[3].num = result.RESULTVALUE.BL.BHG.NUM; //不合格奖
            this.leftEchartList[0].per = result.RESULTVALUE.BL.YX.BL; //优秀奖
            this.leftEchartList[1].per = result.RESULTVALUE.BL.LH.BL; //良好奖
            this.leftEchartList[2].per = result.RESULTVALUE.BL.HG.BL; //合格奖
            this.leftEchartList[3].per = result.RESULTVALUE.BL.BHG.BL; //不合格奖
            this.$refs.echartLeft.drawEchart();

            if (result.RESULTVALUE.INFO.length) {
                this.timeList = result.RESULTVALUE.INFO;
                this.timeActiveNum = this.timeList[0].SJ;
                this.getTableData();
            }
        },
        async getTableData() {
            let result = await this.GetWSKPDataInfo({
                mjbh: this.detail.MJBH, //民居编号
                sj: this.timeActiveNum,
            });
            this.centList = result.RESULTVALUE.PF.INFO;
            this.average = result.RESULTVALUE.PF.PJF.FS;
            // 获轮播图数据
            this.carouselList = this.common.arrayDevide(
                result.RESULTVALUE.FJ,
                6
            );

            this.tableListId = [];
            this.tableListColumn = [];

            result.RESULTVALUE.PFMX.map((item) => {
                if (!this.tableListId.includes(item.PFXMID)) {
                    this.tableListId.push(item.PFXMID);
                    let obj = {
                        id: item.PFXMID,
                        num: 1,
                        name: item.PFXM,
                        idChild: [item.DFXID],
                    };
                    this.tableListColumn.push(obj);
                } else {
                    this.tableListColumn.map((itemInner) => {
                        if (itemInner.id == item.PFXMID) {
                            itemInner.num++;
                            itemInner.idChild.push(item.DFXID);
                        }
                    });
                }
            });

            result.RESULTVALUE.PFMX.map((item, index) => {
                item.ID = index;
                this.tableListColumn.map((itemInner) => {
                    itemInner.idMin = String(Math.min(...itemInner.idChild));
                    if (itemInner.id == item.PFXMID) {
                        item.num = itemInner.num;
                    }
                });
            });
            this.tableListIdMin = [];
            this.tableListColumn.map((item) => {
                this.tableListIdMin.push(String(item.idMin));
            });
            this.tableList = result.RESULTVALUE.PFMX;
        },
        objectSpanMethod({ row, column, rowIndex, columnIndex }) {
            if (columnIndex === 1) {
                if (this.tableListIdMin.includes(row.DFXID)) {
                    return {
                        rowspan: row.num,
                        colspan: 1,
                    };
                } else {
                    return {
                        rowspan: 0,
                        colspan: 0,
                    };
                }
            }
        },
        // 选择某一个时间
        getTimeItem(item, index) {
            this.timeActiveNum = item.SJ;
            this.getTableData();
        },
        carouselTurn(num) {
            if (num) {
                // 右侧
                this.carouseNum++;
            } else {
                // 左侧
                this.carouseNum--;
            }
            if (this.carouseNum < 0) {
                this.carouseNum = this.carouselList.length - 1;
            }
            if (this.carouseNum >= this.carouselList.length) {
                this.carouseNum = 0;
            }
            this.$refs.carousel.setActiveItem(this.carouseNum);
        },
        // 导出平均分
        async exportkpTable() {
            let obj = {
                url: window.REQUEST_URL + "PeopleHouseMJDC/ExportWSKPDataInfo",
                name: "",
                para: {
                    sj: this.detail.KPSJ,
                    mjbh: this.detail.MJBH,
                },
            };
            this.common.exportFile(obj);
        },
    },
};
</script>
<style scoped lang="scss">
.tableCompPart {
    width: 100%;
    height: 100%;
    position: relative;
    .timePart {
        margin-bottom: 10px;
        .searchItem {
            margin-left: 10px;
            width: 120px;
        }
    }
    .topPart {
        width: 100%;
        height: 200px;
        background: rgba(0, 0, 0, 0.05);
        margin: 0 auto;
        .leftEchartPart {
            width: 30%;
            height: 100%;
        }
        .rightEchart {
            height: 100%;
        }
    }
    .bottomPart {
        width: 100%;
        margin-top: 10px;
        .timeListPart {
            width: 133px;
            max-height: 500px;
            margin-right: 20px;
            .timeItem {
                width: 100%;
                height: 114px;
                position: relative;
                .time {
                    color: rgba(68, 68, 68, 1);
                    font-size: 14px;
                    position: absolute;
                    right: 30px;
                    bottom: 0;
                }
                .timeActive {
                    color: rgba(2, 159, 167, 1);
                }
                .img {
                    width: 10px;
                    height: 114px;
                }
            }
        }
        .tablePart {
            .scorePart {
                width: 100%;
                height: 100px;
                border-radius: 14px;
                background: rgba(2, 159, 167, 0.1);
                padding: 10px 20px;
                .averagePart {
                    .name {
                        color: rgba(68, 68, 68, 1);
                        font-size: 14px;
                        position: relative;
                        top: 10px;
                    }
                    .num {
                        color: rgba(2, 159, 167, 1);
                        font-size: 24px;
                    }
                }
                .centList {
                    margin-top: 10px;
                    .centItem {
                        width: 100px;
                        color: rgba(68, 68, 68, 1);
                        font-size: 14px;
                    }
                }
            }
            .kpTable {
                font-size: 16px;
                color: #0ba0a4;
                margin: 10px 0;
            }
            .carouselPart {
                margin-top: 10px;
                .carouselImg {
                    margin: 0 10px;
                    .imgList {
                        position: relative;
                        margin-right: 60px;
                        .mask {
                            width: 100%;
                            height: 40px;
                            background: rgba(0, 0, 0, 0.3);
                            border-bottom-left-radius: 10px;
                            border-bottom-right-radius: 10px;
                            position: absolute;
                            left: 0;
                            bottom: 0;
                            padding: 0 4px;
                            .name {
                                font-size: 14px;
                                color: rgba(255, 255, 255, 1);
                            }
                            .desc {
                                font-size: 12px;
                                color: rgba(255, 255, 255, 1);
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>
