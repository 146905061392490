<template>
    <div class="echartPart">
        <div class="echartDetail flex">
            <div class="echart" :id="id"></div>
            <div class="legend flex-x-center flex-column" v-if="total">
                <div
                    v-for="(item, index) in listAry"
                    :key="index"
                    class="flex-y-center legendItem"
                >
                    <div
                        class="color"
                        :style="{ background: item.color }"
                    ></div>
                    <div class="name">{{ item.name }} ：{{ item.per }}</div>
                </div>
            </div>
        </div>

        <div class="noData" v-if="!total">
            <SnNoData
                :noDataState="true"
                noDataText="暂无数据"
                color="rgb(151 147 147)"
            ></SnNoData>
        </div>
    </div>
</template>
<script>
import { mapActions, mapMutations, mapGetters } from "vuex";
export default {
    name: "",
    components: {},
    data() {
        return {
            echart: null,
            total: 0,
        };
    },
    computed: {
        ...mapGetters({}),
    },
    props: {
        id: {
            type: String,
            default() {
                let str = String(Math.random());
                return str;
            },
        },
        listAry: {
            type: Array,
            default() {
                return [];
            },
        },
        //  半径
        radius: {
            type: Array,
            default() {
                return ["50%", "60%"];
            },
        },
        //  位置
        center: {
            type: Array,
            default() {
                return ["50%", "50%"];
            },
        },
        dw: {
            type: String,
            default() {
                return "";
            },
        },
    },
    watch: {},
    created() {},
    mounted() {},
    methods: {
        ...mapMutations({}),
        ...mapActions([]),
        drawEchart() {
            let legendList = [];
            let colorList = [];
            this.total = 0;
            let seriesData = [];

            this.listAry.map((item) => {
                this.total += item.num;
                colorList.push(item.color);
                legendList.push(item.name);
                let obj = {
                    name: item.name,
                    value: item.num,
                };
                seriesData.push(obj);
            });
            if (!this.total) {
                this.echart = this.$echarts.dispose(
                    document.getElementById(this.id)
                );
                return;
            }
            let options = {
                title: {
                    text: this.total,
                    subtext: this.dw,
                    textStyle: {
                        fontSize: 36,
                        color: "rgba(2,159,167,1)",
                        fontWeight: "400",
                    },
                    left: "center",
                    top: "middle",
                    itemGap: -15, //主副标题纵向间隔，单位px，默认为10
                    subtextStyle: {
                        fontSize: 12,
                        color: "rgba(68,68,68,1)",
                    },
                },
                color: colorList,
                tooltip: {
                    trigger: "item",
                },
                series: [
                    {
                        type: "pie",
                        radius: this.radius,
                        center: ["50%", "50%"],
                        hoverAnimation: false,
                        label: {
                            normal: {
                                show: false,
                            },
                        },
                        labelLine: {
                            normal: {
                                show: false,
                            },
                        },
                        data: seriesData,
                    },
                ],
            };
            this.echart = this.$echarts.dispose(
                document.getElementById(this.id)
            );
            this.echart = this.$echarts.init(document.getElementById(this.id));
            this.echart.setOption(options);
        },
    },
};
</script>
<style scoped lang="scss">
.echartPart {
    width: 100%;
    height: 100%;
    position: relative;
    .echartDetail {
        width: 100%;
        height: 100%;
        .echart {
            width: 50%;
            height: 100%;
        }
        .legend {
            width: 50%;
            height: 100%;
            .legendItem {
                margin-top: 10px;
                .color {
                    width: 10px;
                    height: 10px;
                    margin-right: 10px;
                }
                .name {
                    color: rgba(68, 68, 68, 1);
                    font-size: 14px;
                }
            }
        }
    }

    .echart {
        width: 100%;
        height: 100%;
    }
    .noData {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}
</style>
