<template>
    <div class="tableCompPart comTable">
        <!--表单 -->
        <el-table
            row-key="ID"
            :data="listAry"
            style="width: 100%"
            max-height="680"
            id="tableList"
            @selection-change="handleSelectionChange"
        >
            <el-table-column type="selection" width="55"></el-table-column>
            <el-table-column
                type="index"
                label="序号"
                width="55"
            ></el-table-column>
            <el-table-column
                prop="XZC"
                label="行政村"
                align="center"
            ></el-table-column>
            <el-table-column
                prop="XZZ"
                label="村民小组"
                align="center"
            ></el-table-column>
            <el-table-column
                prop="MJBH"
                label="民居编号"
                align="center"
            ></el-table-column>
            <el-table-column
                prop="MJLX"
                label="民居类型"
                align="center"
            ></el-table-column>
            <el-table-column
                prop="KPSJ"
                label="考评时间"
                align="center"
            ></el-table-column>

            <el-table-column
                prop="PJF"
                label="考评分数"
                align="center"
            ></el-table-column>
            <el-table-column prop="" label="考评奖励" align="center">
                <template slot-scope="scope">
                    <div>{{ scope.row.KPJL ? scope.row.KPJL : "--" }}</div>
                </template>
            </el-table-column>

            <el-table-column label="考评记录" width="120" align="center">
                <template slot-scope="scope">
                    <div class="flex-both-center">
                        <img
                            src="@image/icon/view.png"
                            @click="goDetail(scope.row)"
                            class="pointer"
                            title="查看"
                            style="margin-right: 5px"
                        />
                    </div>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>
<script>
import * as TYPES from "@store/modules/mutation-type.js";
import { mapActions, mapMutations, mapGetters } from "vuex";
export default {
    name: "",
    components: {},
    data() {
        return {};
    },
    computed: {},
    props: {
        listAry: {
            type: Array,
            default: [],
        },
    },
    watch: {},
    mounted() {},
    methods: {
        ...mapActions([]),
        // 多选
        handleSelectionChange(val) {
            this.$emit("getKpTableTag", val);
        },
        // 查看详情
        goDetail(row) {
            this.$emit("seeDetailItem", row);
        },
    },
};
</script>
<style scoped lang="scss">
.tableCompPart {
    width: 100%;
    height: 100%;
    position: relative;
}
</style>
