<template>
    <div class="kpTable">
        <el-table
            row-key="ID"
            :data="listAry"
            style="width: 100%"
            :max-height="maxHeight"
        >
            <el-table-column
                v-for="(item, index) in legendList"
                :key="index"
                :prop="item.prop"
                :label="item.name"
                align="center"
                :width="item.width ? item.width : ''"
            ></el-table-column>
        </el-table>
    </div>
</template>
<script>
import { mapActions, mapMutations, mapGetters } from "vuex";
export default {
    name: "",
    components: {},
    data() {
        return {};
    },
    computed: {
        ...mapGetters({}),
    },
    props: {
        listAry: {
            type: Array,
            default() {
                return [];
            },
        },
        legendList: {
            type: Array,
            default() {
                return "";
            },
        },
        maxHeight: {
            type: String,
            default() {
                return "220";
            },
        },
    },
    watch: {},
    created() {},
    mounted() {},
    methods: {
        ...mapMutations({}),
        ...mapActions([]),
    },
};
</script>
<style scoped lang="scss">
</style>
