<template>
    <div class="tablePart">
        <!-- 搜索部分 -->
        <div class="searchPart flex-y-center">
            <el-select
                v-model="xzcId"
                placeholder="请选择行政村"
                class="searchItem sjtbSelect"
                clearable
                @change="changeXzc()"
                filterable
            >
                <el-option
                    v-for="(item, index) in xzcList"
                    :key="index"
                    :label="item.MC"
                    :value="item.BM"
                >
                </el-option>
            </el-select>
            <el-select
                v-model="cmxzId"
                placeholder="请选择村民小组"
                clearable
                class="searchItem"
                @change="changeCmxz()"
                filterable
            >
                <el-option
                    v-for="(item, index) in cmxzList"
                    :key="index"
                    :label="item.MC"
                    :value="item.BM"
                >
                </el-option>
            </el-select>
            <el-input
                v-model="jmbh"
                placeholder="请输入民居编号"
                class="searchItem"
                clearable
                @keyup.enter.native="changeJmbh()"
                @input="changeJmbh()"
            ></el-input>

            <el-select
                v-model="kpjl"
                placeholder="请选择考评奖励"
                class="searchItem"
                multiple
                collapse-tags
                @change="kpjlChange()"
            >
                <el-option
                    v-for="(item, index) in kpjlList"
                    :key="index"
                    :label="item.MC"
                    :value="item.ID"
                >
                </el-option>
            </el-select>
            <el-date-picker
                v-model="startTime"
                class="searchItem"
                align="left"
                type="month"
                value-format="yyyy-MM"
                format="yyyy-MM"
                @change="startTimeChange()"
            >
            </el-date-picker>
            <el-date-picker
                v-model="endTime"
                class="searchItem"
                align="left"
                type="month"
                value-format="yyyy-MM"
                format="yyyy-MM"
                @change="endTimeChange()"
            >
            </el-date-picker>
            <div class="searchBtn pointer" @click="getInitTable()">查询</div>

            <div class="searchBtn pointer" @click="openKptjPanel()">
                考评统计
            </div>
            <div class="exportBtn pointer flex">
                <div
                    v-for="(item, index) in exportList"
                    :key="index"
                    @click="exportData(item)"
                    class="exportItem pointer"
                >
                    {{ item.name }}
                </div>
            </div>
        </div>
        <!--表单 -->
        <div>
            <kpTable
                :listAry="listAry"
                @seeDetailItem="seeDetailItem"
                @getKpTableTag="getKpTableTag"
            ></kpTable>
        </div>
        <!-- 分页 -->
        <div class="pagePart">
            <el-pagination
                small
                class="pages"
                @current-change="handleCurrentChange"
                @size-change="handleSizeChange"
                :current-page.sync="pageIndex"
                :page-size="pageSize"
                :page-sizes="eachtotal"
                layout="total, sizes, prev, pager, next, jumper"
                :total="totalNum"
            >
            </el-pagination>
        </div>
        <!-- 考评统计 -->
        <SnDialog :dialogData="dialogKptj">
            <kptjTable v-if="dialogKptj.dialog"></kptjTable>
        </SnDialog>
        <!-- 查看详情 -->
        <SnDialog :dialogData="dialogCkxq">
            <kpDetail ref="kpjlDetail"></kpDetail>
        </SnDialog>
    </div>
</template>
<script>
import * as TYPES from "@store/modules/mutation-type.js";
import { mapActions, mapMutations, mapGetters } from "vuex";
import kpTable from "./comp/kpTable.vue";
import kptjTable from "./comp/kptjTable.vue"; //考评统计
import kpDetail from "./comp/kpDetail.vue"; //考评详情
export default {
    name: "",
    components: {
        kpTable,
        kptjTable,
        kpDetail,
    },
    data() {
        return {
            xzcId: "",
            xzcList: [],
            cmxzId: "",
            cmxzList: [],
            jmbh: "", //民居编号
            kpjl: [], //考评奖励
            kpjlList: [], //考评奖励列表
            startTime: this.common.getTimeYm(), //开始时间
            endTime: this.common.getTimeYm(), //结束时间
            exportList: [
                {
                    name: "下载考评表",
                },
                {
                    name: "导出数据",
                },
            ],
            pageIndex: 1,
            pageSize: 15,
            totalNum: 0,
            eachtotal: [15, 30, 50],
            listAry: [],
            kpTableTagList: [], //选中考评表
            dialogKptj: {
                title: "考评统计",
                width: "1500px",
                action: false,
                dialog: false,
            },
            dialogCkxq: {
                title: "查看详情",
                width: "1279px",
                detail: null,
                action: false,
                dialog: false,
            },
        };
    },
    computed: {
        ...mapGetters({}),
    },
    watch: {},
    created() {},
    mounted() {
        let year = this.startTime.split("-")[0];

        let month = parseInt(this.startTime.split("-")[1]);
        console.log(
            "%c 🍩 month: ",
            "font-size:20px;background-color: #42b983;color:#fff;",
            month
        );
        console.log(
            "%c 🥘 year: ",
            "font-size:20px;background-color: #93C0A4;color:#fff;",
            year
        );
        // 基数
        if (month % 2 != 0) {
            if (month < 10) {
                if (month - 1 == 0) {
                    this.startTime = `${year - 1}-12`;
                } else {
                    this.startTime = `${year}-0${month - 1}`;
                }
            } else {
                this.startTime = `${year}-${month - 1}`;
            }
        } else {
            if (month < 10) {
                if (month - 2 == 0) {
                    this.startTime = `${year}-01`;
                } else {
                    this.startTime = `${year}-0${month - 2}`;
                }
            } else {
                this.startTime = `${year}-${month - 2}`;
            }
        }
        console.log(
            "%c 🍷 this.startTime: ",
            "font-size:20px;background-color: #465975;color:#fff;",
            this.startTime
        );
        this.getCunList();
        this.getXzList();
        this.getKpList();
        this.getTableList();
    },
    methods: {
        ...mapMutations({}),
        ...mapActions(["GetXZQHByCode", "GetKPJLEnum", "GetWSKPList"]),
        // 获取 村 列表
        async getCunList() {
            let result = await this.GetXZQHByCode({
                code: "",
                userid: "",
                lx: "",
            });
            // console.log("获取 村 列表", result);
            this.xzcList = result.RESULTVALUE;
        },
        // 获取 小组 列表
        async getXzList() {
            let result = await this.GetXZQHByCode({
                code: this.xzcId,
                userid: "",
                lx: 1, //0 存 1组
            });
            // console.log("获取 小组 列表", result);
            this.cmxzList = result.RESULTVALUE;
        },
        async getKpList() {
            let result = await this.GetKPJLEnum({});
            this.kpjlList = result.RESULTVALUE;
        },
        // 行政村
        changeXzc() {
            this.pageIndex = 1;
            this.getTableList();
        },
        //村民小组
        changeCmxz() {
            this.pageIndex = 1;
            this.getTableList();
        },
        // 居民编号
        changeJmbh() {
            this.pageIndex = 1;
            this.getTableList();
        },
        // 考评奖励
        kpjlChange() {
            this.pageIndex = 1;
            this.getTableList();
        },
        // 翻页
        handleCurrentChange(val) {
            this.pageIndex = val;
            this.getTableList();
        },
        // 页面条数
        handleSizeChange(val) {
            this.pageSize = val;
            this.getTableList();
        },
        // 开始时间变化
        startTimeChange() {
            this.pageIndex = 1;
            this.getTableList();
        },
        // 结束时间变化
        endTimeChange() {
            this.pageIndex = 1;
            this.getTableList();
        },
        getInitTable() {
            this.pageIndex = 1;
            this.getTableList();
        },
        async getTableList() {
            let result = await this.GetWSKPList({
                xzc: this.xzcId ? this.xzcId : "", //行政村编号
                cmxz: this.cmxzId ? this.cmxzId : "",
                mjbh: this.jmbh ? this.jmbh : "",
                kpjl: this.kpjl.join(","),
                starttime: this.startTime ? this.startTime : "",
                endtime: this.endTime ? this.endTime : "",
                pageIndex: this.pageIndex,
                pageSize: this.pageSize,
            });
            this.listAry = result.RESULTVALUE.DATA;
            this.totalNum = result.RESULTVALUE.TOTAL;
        },
        // 查看详情
        seeDetailItem(item) {
            this.dialogCkxq.title = `${item.MJBH}-考评记录`;
            this.dialogCkxq.dialog = true;
            this.$nextTick(() => {
                this.$refs.kpjlDetail.getMainInfo(item);
            });
        },
        // 选中考评表
        getKpTableTag(list) {
            this.kpTableTagList = list;
        },
        // 导出数据
        exportData(item) {
            let obj = null;
            switch (item.name) {
                case "下载考评表":
                    if (!this.kpTableTagList.length) {
                        this.common.showMsg("请选择考评表", "warning");
                        return;
                    }
                    obj = {
                        url:
                            window.REQUEST_URL +
                            "PeopleHouseMJDC/ExportKPBExcel",
                        name: ``,
                        para: {
                            mjbhAndSj: "",
                        },
                    };
                    let list = [];
                    this.kpTableTagList.map((item) => {
                        list.push(`${item.MJBH}_${item.KPSJ}`);
                    });
                    obj.para.mjbhAndSj = list.join(",");
                    break;
                case "导出数据":
                    obj = {
                        url:
                            window.REQUEST_URL +
                            "PeopleHouseMJDC/ExportMJKPList",
                        name: "",
                        para: {
                            xzc: this.xzcId ? this.xzcId : "",
                            cmxz: this.cmxzId ? this.cmxzId : "",
                            mjbh: this.jmbh ? this.jmbh : "",
                            kpjl: this.kpjl.length ? this.kpjl.join(",") : "",
                            starttime: this.startTime ? this.startTime : "",
                            endtime: this.endTime ? this.endTime : "",
                        },
                    };
                    break;
            }
            this.common.exportFile(obj);
        },
        // 打开考评统计面板
        openKptjPanel() {
            this.dialogKptj.dialog = true;
        },
    },
};
</script>
<style scoped lang="scss">
.tablePart {
    width: 100%;
    .searchPart {
        width: 100%;
        height: 86px;
        position: relative;
        .searchItem {
            width: 160px;
            margin-right: 10px;
        }
        .searchBtn {
            width: 80px;
            height: 38px;
            line-height: 38px;
            text-align: center;
            background: #0ba0a4;
            color: #fff;
            font-size: 14px;
            margin-right: 10px;
        }
        .exportBtn {
            position: absolute;
            right: 0;
            bottom: 10px;
            .exportItem {
                height: 30px;
                line-height: 30px;
                text-align: center;
                color: #fff;
                font-size: 14px;
                padding: 0 10px;
                margin-left: 10px;
                background: #52cb5d;
            }
        }
    }
    .pagePart {
        text-align: center;
        margin-top: 10px;
    }
}
</style>
