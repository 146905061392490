<template>
    <div class="tablePart">
        <!-- 搜索部分 -->
        <div class="searchPart flex-y-center">
            <el-select
                v-model="xzcId"
                placeholder="请选择行政村"
                class="searchItem sjtbSelect"
                clearable
                @change="xzcChange()"
                filterable
            >
                <el-option
                    v-for="(item, index) in xzcList"
                    :key="index"
                    :label="item.MC"
                    :value="item.BM"
                >
                </el-option>
            </el-select>
            <el-select
                v-model="cmxzId"
                placeholder="请选择村民小组"
                clearable
                class="searchItem"
                @change="cmxzChange()"
                filterable
            >
                <el-option
                    v-for="(item, index) in cmxzList"
                    :key="index"
                    :label="item.MC"
                    :value="item.BM"
                >
                </el-option>
            </el-select>
            <el-input
                v-model="jmbh"
                placeholder="请输入民居编号"
                class="searchItem"
                clearable
                @keyup.enter.native="getTableList()"
                @input="cmbhChange()"
            ></el-input>
            <el-select
                v-model="jmlx"
                placeholder="请选择民居类型"
                class="searchItem"
                clearable
                @change="cmlxChange()"
            >
                <el-option
                    v-for="(item, index) in jmlxList"
                    :key="index"
                    :label="item.LABEL"
                    :value="item.CODE"
                >
                </el-option>
            </el-select>
            <!-- adminRoleState -->
            <el-select
                v-model="dcyxm"
                filterable
                clearable
                :placeholder="
                    dataGetZtActiveNum === 0 ? '请选择调查员' : '请选择抽查员'
                "
                class="searchItem"
                @change="dcyxmChange()"
            >
                <el-option
                    v-for="(item, index) in dataGetZtActiveNum === 0
                        ? dcyList
                        : ccYList"
                    :key="index"
                    :value="item.REALNAME"
                    :label="item.REALNAME"
                >
                </el-option>
            </el-select>

            <el-select
                v-if="dataGetZtActiveNum === 0"
                v-model="dcztId"
                placeholder="请选择调查状态"
                class="searchItem"
                multiple
                collapse-tags
                @change="sjztDcyChange()"
            >
                <el-option
                    v-for="(item, index) in dcySjztList"
                    :key="index"
                    :label="item.LABEL"
                    :value="item.CODE"
                >
                </el-option>
            </el-select>
            <el-select
                v-if="dataGetZtActiveNum === 1"
                v-model="ccztId"
                placeholder="请选择抽查状态"
                class="searchItem"
                multiple
                collapse-tags
                @change="sjztccyChange()"
            >
                <el-option
                    v-for="(item, index) in ccySjztList"
                    :key="index"
                    :label="item.LABEL"
                    :value="item.CODE"
                >
                </el-option>
            </el-select>
            <div class="searchBtn pointer" @click="getInitTable()">查询</div>

            <div class="searchBtn pointer" @click="dataMigrationTest()">
                数据迁移
            </div>
            <div class="searchBtn pointer" @click="openMulTaskDialog()">
                批量任务
            </div>
            <!--  -->

            <!-- <div class="exportExcelBtn pointer" @click="exportExcel()">
                导出Excel
            </div>
            <div class="exportWordBtn pointer" @click="exportWord()">
                导出Word
            </div> -->
        </div>
        <!--表单 -->
        <div>
            <!-- <div class="flex-y-center">
                <div>时间间隔s</div>
                <el-input
                    v-model="timeGap"
                    placeholder="请输入"
                    class="searchItem"
                    style="width: 140px"
                    clearable
                ></el-input>
                <div>序号</div>
                <el-input
                    v-model="timeNum"
                    placeholder="请输入"
                    class="searchItem"
                    style="width: 140px"
                    clearable
                ></el-input>
                <div class="exportBtn pointer" @click="start()">
                    开始批量下载{{ timeOpenNum }}
                </div>
            </div> -->

            <DcyTable
                v-if="dataGetZtActiveNum == 0"
                :listAry="listAry"
                :peopleList="dcyList"
                :adminRoleState="adminRoleState"
                :dcyRoleState="dcyRoleState"
                @sendTaskSuccsss="getTableList()"
                @seeDetailItem="seeDetailItem"
                @editDetailItem="editDetailItem"
                @getMulTaskPeo="getMulTaskPeo"
                @exportDetailItem="exportDetailItem"
            ></DcyTable>
            <CcyTable
                v-if="dataGetZtActiveNum == 1"
                :listAry="listAry"
                :peopleList="ccYList"
                :adminRoleState="adminRoleState"
                :ccyRoleState="ccyRoleState"
                @sendTaskSuccsss="getTableList()"
                @seeDetailItem="seeDetailItem"
                @editDetailItem="editDetailItem"
                @getMulTaskPeo="getMulTaskPeo"
                @exportDetailItem="exportDetailItem"
            ></CcyTable>
        </div>
        <!-- 分页 -->
        <div class="pagePart">
            <el-pagination
                small
                class="pages"
                @current-change="handleCurrentChange"
                @size-change="handleSizeChange"
                :current-page.sync="pageIndex"
                :page-size="pageSize"
                :page-sizes="eachtotal"
                layout="total, sizes, prev, pager, next, jumper"
                :total="totalNum"
            >
            </el-pagination>
        </div>

        <!-- 批量下发弹出框 -->
        <SnDialog :dialogData="dialogDataMulTask" @onSubmit="onSubmitMulTask()">
            <el-form
                :model="dialogMulTask"
                status-icon
                ref="dialogMulTask"
                :rules="dialogMulTaskRules"
                label-width="110px"
            >
                <el-form-item
                    label="核查人"
                    prop="people"
                    v-if="dataGetZtActiveNum === 0"
                >
                    <el-select
                        size="mini"
                        placeholder="请选择核查人"
                        clearable
                        v-model="dialogMulTask.people"
                        style="width: 350px"
                    >
                        <el-option
                            v-for="(item, index) in dcyList"
                            :value="item.ID"
                            :label="item.REALNAME"
                            :key="index"
                        ></el-option>
                    </el-select>
                </el-form-item>

                <el-form-item
                    label="核查人"
                    prop="people"
                    v-if="dataGetZtActiveNum === 1"
                >
                    <el-select
                        size="mini"
                        placeholder="请选择核查人"
                        clearable
                        v-model="dialogMulTask.people"
                        style="width: 350px"
                    >
                        <el-option
                            v-for="(item, index) in ccYList"
                            :value="item.ID"
                            :label="item.REALNAME"
                            :key="index"
                        ></el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="起止时间" prop="time">
                    <el-date-picker
                        v-model="dialogMulTask.time"
                        type="daterange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        value-format="yyyy-MM-dd"
                    >
                    </el-date-picker>
                </el-form-item>
            </el-form>
        </SnDialog>
    </div>
</template>
<script>
import * as TYPES from "@store/modules/mutation-type.js";
import { mapActions, mapMutations, mapGetters } from "vuex";
import DcyTable from "./comp/DcyTable.vue";
import CcyTable from "./comp/CcyTable.vue";
export default {
    name: "",
    components: {
        DcyTable,
        CcyTable,
    },
    data() {
        return {
            timeGap: 10,
            timeNum: 0,
            timeOpenNum: 0,
            time: "",
            xzcId: "",
            xzcList: [],
            cmxzId: "",
            cmxzList: [],
            jmbh: "", //民居编号
            jmlx: "", //民居类型
            jmlxList: [
                {
                    LABEL: "F1",
                    CODE: "F1",
                },
                {
                    LABEL: "F2",
                    CODE: "F2",
                },
                {
                    LABEL: "F3",
                    CODE: "F3",
                },
                {
                    LABEL: "F4",
                    CODE: "F4",
                },
            ],

            dcyxm: "", //调查员姓名
            dcztId: [], //调查状态ID
            dcySjztList: [], //状态列表 调查员
            ccztId: [], //抽查状态ID
            ccySjztList: [], //状态列表 抽查员
            pageIndex: 1,
            pageSize: 15, //999
            totalNum: 0,
            eachtotal: [15, 30, 50],
            listAry: [],
            dcyList: [], // 调查员列表
            ccYList: [], // 抽查员列表
            dialogDataMulTask: {
                title: "核查任务下发",
                width: "570px",
                action: true,
                dialog: false,
            },
            dialogMulTask: {
                people: "",
                peopleList: [],
                time: [],
            },
            dialogMulTaskRules: {
                people: [
                    {
                        required: true,
                        message: "请选择核查人",
                        trigger: "blur",
                    },
                ],
                time: [
                    {
                        required: true,
                        message: "请输选择时间",
                        trigger: "blur",
                    },
                ],
            },
            roleId: localStorage.roleId.split(","), //登录人角色id
            userId: localStorage.userId, //用户id
            glyId: window.GLY, //管理员 id
            dcyId: window.DCY, //调查人 id
            ccyId: window.CCY, //抽查人 id
            adminRoleState: false, //角色是否包含 管理员
            ccyRoleState: false, //角色是否包含 抽查员
            dcyRoleState: false, //角色是否包含 调查员
        };
    },
    computed: {
        ...mapGetters({
            dataGetXzc: TYPES.dataGetXzc,
            dataGetCmxz: TYPES.dataGetCmxz,
            dataGetJmbh: TYPES.dataGetJmbh,
            dataGetJmlx: TYPES.dataGetJmlx,
            dataGetDcyxm: TYPES.dataGetDcyxm,
            dataGetSjztDcy: TYPES.dataGetSjztDcy,
            dataGetSjztCcy: TYPES.dataGetSjztCcy,
            dataGetPageIndex: TYPES.dataGetPageIndex,
            dataGetZtActiveNum: TYPES.dataGetZtActiveNum, //0 调查 1 抽查
        }),
    },
    watch: {
        dataGetZtActiveNum: {
            immediate: true,
            handler() {
                this.dcyxm = "";
                this.SET_DATAGEDCYXM("");
                this.stateChange(this.dataGetZtActiveNum);
            },
        },
    },
    created() {
        // console.log("this.dataGetXzc", this.dataGetXzc);
        // console.log("this.dataGetCmxz", this.dataGetCmxz);
        // console.log("this.dataGetJmbh", this.dataGetJmbh);
        // console.log("this.dataGetDcyxm", this.dataGetDcyxm);
        // console.log("this.dataGetSjztDcy", this.dataGetSjztDcy);
        // console.log("this.dataGetPageIndex", this.dataGetPageIndex);
        this.xzcId = this.dataGetXzc;
        this.cmxzId = this.dataGetCmxz;
        this.jmbh = this.dataGetJmbh;
        this.jmlx = this.dataGetJmlx;
        this.dcztId = this.dataGetSjztDcy;
        this.ccztId = this.dataGetSjztCcy;
        this.pageIndex = Number(this.dataGetPageIndex);
    },
    mounted() {
        this.adminRoleState = this.roleId.includes(this.glyId);
        this.ccyRoleState = this.roleId.includes(this.ccyId);
        this.dcyRoleState = this.roleId.includes(this.dcyId) || this.userId.includes('343b78e1-8af2-11eb-b3ce-000c2977b7fd');
        this.getCunList();
        this.getZtList();
        this.getXzList();
        this.getHcrList();
        this.initWithRole();
        this.getTableList();
    },
    methods: {
        ...mapMutations({
            SET_DATAGETXZC: TYPES.SET_DATAGETXZC,
            SET_DATAGETCMXZ: TYPES.SET_DATAGETCMXZ,
            SET_DATAGEJMBH: TYPES.SET_DATAGEJMBH,
            SET_DATAGEJMLX: TYPES.SET_DATAGEJMLX,
            SET_DATAGEDCYXM: TYPES.SET_DATAGEDCYXM,
            SET_DATAGETSJZTDCY: TYPES.SET_DATAGETSJZTDCY,
            SET_DATAGETSJZTCCY: TYPES.SET_DATAGETSJZTCCY,
            SET_DATAPAGEINDEX: TYPES.SET_DATAPAGEINDEX,
            SET_DATAGETZTACTIVENUM: TYPES.SET_DATAGETZTACTIVENUM,
        }),
        ...mapActions([
            "GetXZQHByCode",
            "GetAllEnumInfobytableID",
            "GetJmdcList",
            "GetCcyList",
            "ExportJmdcList",
            "GetTaskCjrList",
            "InitJmdcTask",
            "InitJmdcccbData",
        ]),
        initWithRole() {
            // 管理员
            // 抽查员
            // 调查员
            // 不是管理员的时候查看自己的数据
            // if (this.adminRoleState) {
            //     this.dcyxm = this.dataGetDcyxm;
            // } else {
            //     this.dcyxm = localStorage.userRealName;
            // }
            switch (this.roleId.length) {
                case 1:
                    this.initOneRole();
                    break;
                case 2:
                case 3:
                    this.stateChange(this.dataGetZtActiveNum);
                    break;
            }
        },
        initOneRole() {
            switch (this.roleId[0]) {
                case this.glyId: //管理员
                    this.stateChange(this.dataGetZtActiveNum);
                    break;
                case this.ccyId: //抽查员
                    this.stateChange(1);
                    break;
                case this.dcyId: //调查员
                    this.stateChange(0);
                    break;
            }
        },
        //管理员切换 抽查 调查
        stateChange(index) {
            this.SET_DATAGETZTACTIVENUM(index);
            this.$nextTick(() => {
                this.getTableList();
            });
        },
        // 行政村
        xzcChange() {
            if (this.xzcId) {
                this.SET_DATAGETXZC(this.xzcId);
            } else {
                this.SET_DATAGETXZC("");
            }
            this.getXzList();
        },
        // 村民小组
        cmxzChange() {
            if (this.cmxzId) {
                this.SET_DATAGETCMXZ(this.cmxzId);
            } else {
                this.SET_DATAGETCMXZ("");
            }
            this.getTableList();
        },
        // 居民编号
        cmbhChange() {
            if (this.jmbh) {
                this.SET_DATAGEJMBH(this.jmbh);
            } else {
                this.SET_DATAGEJMBH("");
            }
        },
        // 居民类型
        cmlxChange() {
            if (this.jmlx) {
                this.SET_DATAGEJMLX(this.jmlx);
            } else {
                this.SET_DATAGEJMLX("");
            }
            this.getTableList();
        },
        // 调查员姓名
        dcyxmChange() {
            if (this.dcyxm) {
                this.SET_DATAGEDCYXM(this.dcyxm);
            } else {
                this.SET_DATAGEDCYXM("");
            }
            this.getTableList();
        },
        // 调查状态
        sjztDcyChange() {
            this.SET_DATAGETSJZTDCY(this.dcztId);
            this.getTableList();
        },
        // 抽查状态
        sjztccyChange() {
            this.SET_DATAGETSJZTCCY(this.ccztId);
            this.getTableList();
        },
        // 获取 村 列表
        async getCunList() {
            let result = await this.GetXZQHByCode({
                code: "",
                userid: "",
                lx: "",
            });
            // console.log("获取 村 列表", result);
            this.xzcList = result.RESULTVALUE;
        },
        // 获取 小组 列表
        async getXzList() {
            let result = await this.GetXZQHByCode({
                code: this.xzcId,
                userid: "",
                lx: 1, //0 存 1组
            });
            // console.log("获取 小组 列表", result);
            this.cmxzList = result.RESULTVALUE;
        },
        // 获取 状态 列表
        async getZtList() {
            let result = await this.GetAllEnumInfobytableID({
                bid: "jmdcqkb",
            });
            result.RESULTVALUE.map((item) => {
                if (item.COLUMNID === "SJZT") {
                    this.dcySjztList = item.DATAITEMS;
                }
                if (item.COLUMNID === "CCZT") {
                    this.ccySjztList = item.DATAITEMS;
                }
            });
        },
        getInitTable() {
            this.pageIndex = 1;
            this.SET_DATAPAGEINDEX(1);
            this.getTableList();
        },
        async getTableList() {
            let url = "";
            let sjzt = "";
            // 调查
            if (this.dataGetZtActiveNum === 0) {
                url = "GetJmdcList";
                sjzt = this.dcztId.join(",");
            } else {
                url = "GetCcyList";
                sjzt = this.ccztId.join(",");
            }

            let result = await this[url]({
                // userId: this.roleId.includes(window.SUPER_ADMIN_ROLEID)
                //     ? ""
                //     : localStorage.userId,
                xzcbm: this.xzcId ? this.xzcId : "", //行政村编号
                xzzbm: this.cmxzId ? this.cmxzId : "",
                jmbh: this.jmbh ? this.jmbh : "",
                dcyxm: this.dcyxm ? this.dcyxm : "",
                sjzt: sjzt,
                pageIndex: this.pageIndex,
                pageSize: this.pageSize,
                mjlx: this.jmlx ? this.jmlx : "",
            });

            this.listAry = result.RESULTVALUE.DATA;
            this.totalNum = result.RESULTVALUE.TOTAL;
        },
        start() {
            this.timeOpenNum = 0;
            this.autoLoad(this.listAry[this.timeNum], this.timeNum);
        },
        autoLoad(item, index) {
            this.timeOpenNum++;
            let obj = item.MJBH + item.MJLX;
            console.log(
                "%c 🍍 obj: ",
                "font-size:20px;background-color: #3F7CFF;color:#fff;",
                obj,
                index
            );
            let url = `${window.REQUEST_URL_CTMJ}ExportData/ExportWord?id=${item.ID}&lx=1`;
            window.open(url, "_self", "", false);
            setTimeout(() => {
                let num = index + 1;
                if (num <= this.listAry.length) {
                    this.autoLoad(this.listAry[num], num);
                } else {
                    console.log(
                        "%c 🧀 所有下载结束: ",
                        "font-size:20px;background-color: #7F2B82;color:#fff;"
                    );
                }
            }, this.timeGap * 1000);
        },
        // 翻页
        handleCurrentChange(val) {
            this.SET_DATAPAGEINDEX(val);
            this.pageIndex = val;
            this.getTableList();
        },
        // 页面条数
        handleSizeChange(val) {
            this.pageSize = val;
            this.getTableList();
        },
        // 查看详情
        seeDetailItem(row) {
            let typeId = null;
            if (this.dataGetZtActiveNum == 0) {
                typeId = "0"; //调查数据
            } else {
                typeId = "1"; //抽查数据
            }

            this.$router.push({
                name: "detail",
                params: {
                    size: "1", // 1 查看 2 编辑
                    strId: row.ID,
                    mjbh: row.MJBH,
                    typeId: typeId, //0 调查 1 抽查
                },
            });
        },
        // 编辑详情
        editDetailItem(row) {
            let typeId = null;
            if (this.dataGetZtActiveNum == 0) {
                typeId = "0"; //调查数据
            } else {
                typeId = "1"; //抽查数据
            }

            this.$router.push({
                name: "detail",
                params: {
                    size: "2", // 1 查看 2 编辑
                    strId: row.ID,
                    typeId: typeId, //0 调查 1 抽查
                    mjbh: row.MJBH,
                },
            });
        },
        // 导出某一条数据
        exportDetailItem(item) {
            let lx = "";
            // 1调查  2抽查
            if (this.dataGetZtActiveNum === 0) {
                lx = 1;
            } else {
                lx = 2;
            }
            let url = `${window.REQUEST_URL_CTMJ}ExportData/ExportWord?id=${item.ID}&lx=${lx}`;
            window.open(url);
            // let obj = {
            //     url: window.REQUEST_URL_CTMJ + "ExportData/ExportWord",
            //     // name: `传统民居遗产监测.xls`,
            //     para: {
            //         id: item.ID,
            //         lx: lx,
            //     },
            // };
            // this.common.exportFile(obj);
        },
        // 导出
        exportExcel() {
            let obj = {
                url: window.REQUEST_URL_CTMJ + "Jmdc/ExportJmdcList",
                name: `传统民居遗产监测.xls`,
                para: {
                    exportType: 3,
                },
            };
            this.common.exportFile(obj);
        },
        exportWord() {
            let lx = "";
            // 1调查  2抽查
            if (this.dataGetZtActiveNum === 0) {
                lx = 1;
            } else {
                lx = 2;
            }
            if (this.dialogMulTask.peopleList.length) {
                let idList = [];
                this.dialogMulTask.peopleList.map((item) => {
                    idList.push(item.ID);
                });
                let url = `${
                    window.REQUEST_URL_CTMJ
                }ExportData/ExportZip?id=${idList.join(",")}&lx=${lx}`;
                console.log(
                    "%c 🥪 url: ",
                    "font-size:20px;background-color: #F5CE50;color:#fff;",
                    url
                );
                window.open(url);
            } else {
                this.common.showMsg("请选择", "warning");
            }
        },
        // 核查人列表
        async getHcrList() {
            let resultWhc = await this.GetTaskCjrList({
                lx: 1, //1调查人 2抽查人
            });

            let resultYhc = await this.GetTaskCjrList({
                lx: 2, //1调查人 2抽查人
            });
            this.dcyList = resultWhc.RESULTVALUE; // 调查员列表
            this.ccYList = resultYhc.RESULTVALUE; // 抽查员列表
        },
        // 获取多人发任务
        getMulTaskPeo(ary) {
            this.dialogMulTask.peopleList = ary;
        },
        openMulTaskDialog(item) {
            this.dialogMulTask.people = "";
            this.dialogMulTask.time = [];
            if (this.dialogMulTask.peopleList.length) {
                this.dialogDataMulTask.dialog = true;
                this.$nextTick(() => {
                    this.$refs["dialogMulTask"].clearValidate();
                });
            } else {
                this.common.showMsg("请选择", "warning");
            }
        },
        onSubmitMulTask() {
            this.$refs.dialogMulTask.validate((valid) => {
                if (valid) {
                    let list = [];
                    this.dialogMulTask.peopleList.map((item) => {
                        let obj = {
                            RWLX: this.dataGetZtActiveNum === 0 ? 0 : 2, //'任务类型 0：采集任务  2：抽查任务',
                            SJID: item.ID,
                            CJRID: this.dialogMulTask.people,
                            RWKSSJ: this.dialogMulTask.time[0], //任务开始时间
                            RWJSSJ: this.dialogMulTask.time[1], //任务结束时间
                            TJSJ: this.common.getTimeYmdhms(), //提交时间
                            CJSJ: this.common.getTimeYmdhms(), //创建时间
                        };
                        list.push(obj);
                    });

                    this.onSubmitWhcAction(list);
                } else {
                    return false;
                }
            });
        },
        async onSubmitWhcAction(list) {
            let result = await this.InitJmdcTask({
                jsonStr: list,
            });
            if (result.ISSUCCESS) {
                this.common.showMsg(result.RESULTDESCRIPTION, "success");
                this.getTableList();
            } else {
                this.common.showMsg(result.RESULTDESCRIPTION, "warning");
            }
            this.dialogMulTask.people = "";
            this.dialogMulTask.time = [];
            this.dialogDataMulTask.dialog = false;
        },
        // 数据迁移
        dataMigrationTest() {
            this.$confirm("数据迁移, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
                cancelButtonClass: "",
            })
                .then(() => {
                    this.dataMigration();
                })
                .catch(() => {
                    this.common.showMsg("已取消", "info");
                });
        },
        async dataMigration() {
            let result = await this.InitJmdcccbData({});
            if (result.ISSUCCESS) {
                this.common.showMsg("数据迁移成功", "success");
            } else {
                this.common.showMsg("数据迁移失败", "warning");
            }
        },
    },
};
</script>
<style scoped lang="scss">
.tablePart {
    width: 100%;
    .searchPart {
        width: 100%;
        height: 86px;
        position: relative;
        .searchItem {
            width: 160px;
            margin-right: 10px;
        }
        .searchBtn {
            width: 80px;
            height: 38px;
            line-height: 38px;
            text-align: center;
            background: #0ba0a4;
            color: #fff;
            font-size: 14px;
            margin-right: 10px;
        }

        .exportExcelBtn {
            padding: 0 10px;
            position: absolute;
            right: 0;
            bottom: 10px;
            height: 30px;
            line-height: 30px;
            text-align: center;
            background: #52cb5d;
            color: #fff;
            font-size: 14px;
        }
        .exportWordBtn {
            padding: 0 10px;
            position: absolute;
            right: 100px;
            bottom: 10px;
            height: 30px;
            line-height: 30px;
            text-align: center;
            background: #52cb5d;
            color: #fff;
            font-size: 14px;
        }
    }
    .tablePart {
    }
    .pagePart {
        text-align: center;
        margin-top: 10px;
    }
}

.exportBtn {
    padding: 0 10px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    background: #52cb5d;
    color: #fff;
    font-size: 14px;
}
</style>
