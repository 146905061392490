<template>
    <div class="tableCompPart">
        <!--表单 -->
        <div class="comTable">
            <el-table
                row-key="ID"
                :data="listAry"
                style="width: 100%"
                max-height="680"
                id="tableList"
                @selection-change="handleSelectionChange"
            >
                <el-table-column type="selection" width="55"></el-table-column>
                <el-table-column
                    type="index"
                    label="序号"
                    width="55"
                ></el-table-column>
                <el-table-column
                    prop="XZC"
                    label="行政村"
                    align="center"
                ></el-table-column>
                <el-table-column
                    prop="XZZ"
                    label="村民小组"
                    align="center"
                ></el-table-column>
                <el-table-column
                    prop="MJLX"
                    label="民居类型"
                    align="center"
                ></el-table-column>
                <el-table-column
                    prop="MJBH"
                    label="民居编号"
                    align="center"
                ></el-table-column>
                <el-table-column
                    prop="HZXM"
                    label="户主姓名"
                    align="center"
                ></el-table-column>

                <el-table-column
                    prop="DCRXM"
                    label="调查人员"
                    align="center"
                ></el-table-column>
                <!-- 调查 -->
                <el-table-column key="0" label="数据状态" align="center">
                    <template slot-scope="scope">
                        <div>
                            <div
                                class="pointer"
                                :style="{
                                    color: getStateColor(scope.row.SJZT),
                                    textDecoration:
                                        scope.row.SJZT === '3'
                                            ? ''
                                            : 'underline',
                                }"
                                @click="openWhcDialog(scope.row)"
                            >
                                {{ scope.row.SJZT_DESC }}
                            </div>
                        </div>
                    </template>
                </el-table-column>

                <el-table-column
                    key="1"
                    prop="DCSJ"
                    label="调查日期"
                    align="center"
                ></el-table-column>

                <el-table-column label="操作" width="120" align="center">
                    <template slot-scope="scope">
                        <div class="actBtnPart flex-both-center">
                            <img
                                src="@image/icon/view.png"
                                @click="goDetail(scope.row)"
                                class="pointer"
                                title="查看"
                            />

                            <img
                                src="@image/icon/edit.png"
                                v-if="dcyRoleState"
                                @click="editDetail(scope.row)"
                                class="pointer"
                                title="编辑"
                            />

                            <img
                                src="@image/icon/load.png"
                                @click="exportDetail(scope.row)"
                                class="pointer"
                                title="导出"
                            />

                            <img
                                src="@image/icon/check_record.png"
                                @click="checkDetail(scope.row)"
                                class="pointer"
                                title="巡查记录"
                            />
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </div>

        <!-- 未核查弹出框 -->
        <SnDialog :dialogData="dialogDataWhc" @onSubmit="onSubmitWhcTest()">
            <el-form
                :model="dialogWhc"
                status-icon
                ref="dialogWhc"
                :rules="dialogWhcRules"
                label-width="110px"
            >
                <el-form-item label="核查人" prop="people">
                    <el-select
                        size="mini"
                        placeholder="请选择核查人"
                        clearable
                        v-model="dialogWhc.people"
                        style="width: 350px"
                    >
                        <el-option
                            v-for="(item, index) in peopleList"
                            :value="item.ID"
                            :label="item.REALNAME"
                            :key="index"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="起止时间" prop="time">
                    <el-date-picker
                        v-model="dialogWhc.time"
                        type="daterange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        value-format="yyyy-MM-dd"
                    >
                    </el-date-picker>
                </el-form-item>
            </el-form>
        </SnDialog>

        <!-- 巡查 弹出框 -->
        <SnDialog :dialogData="dialogCheck">
            <CheckDialog ref="CheckDialog"></CheckDialog>
        </SnDialog>
    </div>
</template>
<script>
import * as TYPES from "@store/modules/mutation-type.js";
import { mapActions, mapMutations, mapGetters } from "vuex";
import CheckDialog from "./CheckDialog";
export default {
    name: "",
    components: {
        CheckDialog,
    },
    data() {
        return {
            dialogDataWhc: {
                title: "核查任务下发",
                width: "570px",
                action: true,
                dialog: false,
            },
            dialogWhc: {
                ztid: "",
                sjid: "",
                cjrid: "",
                people: "",
                time: [],
            },
            dialogWhcRules: {
                people: [
                    {
                        required: true,
                        message: "请选择核查人",
                        trigger: "blur",
                    },
                ],
                time: [
                    {
                        required: true,
                        message: "请输选择时间",
                        trigger: "blur",
                    },
                ],
            },
            dialogCheck: {
                title: "巡查记录",
                width: "1463px",
                action: false,
                dialog: false,
            },
        };
    },
    computed: {},
    props: {
        listAry: {
            type: Array,
            default: [],
        },
        peopleList: {
            type: Array,
            default: [],
        },
        // 登陆角色是否包含 管理员
        adminRoleState: {
            type: Boolean,
            default: false,
        },
        // 登陆角色是否包含 调查员
        dcyRoleState: {
            type: Boolean,
            default: false,
        },
    },
    watch: {},
    mounted() {},
    methods: {
        ...mapActions(["InitJmdcTask", "GetRwInfo"]),
        // 多选
        handleSelectionChange(val) {
            this.$emit("getMulTaskPeo", val);
        },
        // 查看详情
        goDetail(row) {
            this.$emit("seeDetailItem", row);
        },
        // 编辑详情
        editDetail(row) {
            this.$emit("editDetailItem", row);
        },
        // 导出详情
        exportDetail(row) {
            this.$emit("exportDetailItem", row);
        },
        // 巡查
        checkDetail(row) {
            if (!row.MJBH) {
                this.common.showMsg("暂无民居编号", "warning");
                return;
            }
            this.dialogCheck.dialog = true;
            this.$nextTick(() => {
                this.$refs.CheckDialog.getTimeList(row);
            });
        },
        // 打开未核查
        openWhcDialog(item) {
            this.dialogWhc.people = "";
            this.dialogWhc.time = [];
            // if (this.adminRoleState) {
            this.getDcyInfo(item);
            this.dialogWhc.sjid = item.ID;
            this.dialogDataWhc.dialog = true;
            this.$nextTick(() => {
                this.$refs["dialogWhc"].clearValidate();
            });
            // } else {
            //     this.common.showMsg("仅管理员可下发核查任务", "warning");
            // }
        },
        async getDcyInfo(item) {
            let result = await this.GetRwInfo({
                rwlx: 0, // 0调查  2：抽查
                sjid: item.ID,
            });
            if (result.RESULTVALUE.length) {
                let obj = result.RESULTVALUE[0];
                this.dialogWhc.people = obj.CJRID;
                this.dialogWhc.time = [obj.RWKSSJ, obj.RWJSSJ];
            }
        },
        onSubmitWhcTest() {
            this.$refs.dialogWhc.validate((valid) => {
                if (valid) {
                    let obj = {
                        RWLX: 0, //'任务类型 0：采集任务  2：抽查任务',
                        SJID: this.dialogWhc.sjid,
                        CJRID: this.dialogWhc.people,
                        RWKSSJ: this.dialogWhc.time[0], //任务开始时间
                        RWJSSJ: this.dialogWhc.time[1], //任务结束时间
                        TJSJ: this.common.getTimeYmdhms(), //提交时间
                        CJSJ: this.common.getTimeYmdhms(), //创建时间
                    };
                    this.onSubmitWhcAction(obj);
                } else {
                    return false;
                }
            });
        },
        async onSubmitWhcAction(obj) {
            let result = await this.InitJmdcTask({
                jsonStr: [obj],
            });
            if (result.ISSUCCESS) {
                this.common.showMsg(result.RESULTDESCRIPTION, "success");
                this.$emit("sendTaskSuccsss");
            } else {
                this.common.showMsg(result.RESULTDESCRIPTION, "warning");
            }
            this.dialogWhc.people = "";
            this.dialogWhc.time = [];
            this.dialogDataWhc.dialog = false;
        },
        getStateColor(num) {
            let color = "";
            switch (num) {
                case 1: //未
                    color = "#ff0000";
                    break;
                case 2: //中
                    color = "#0ba0a4";
                    break;
                case 3: //结束
                    color = "#44a97f";
                    break;
            }
            return color;
        },
    },
};
</script>
<style scoped lang="scss">
.tableCompPart {
    width: 100%;
    height: 100%;
    position: relative;
    .actBtnPart {
        img {
            margin-right: 5px;
        }
    }
}
</style>
