<template>
    <div class="echartPart">
        <div class="echart" id="rightEchart"></div>
        <div class="noData" v-if="!this.listAry.length">
            <SnNoData
                :noDataState="true"
                noDataText="暂无数据"
                color="rgb(151 147 147)"
            ></SnNoData>
        </div>
    </div>
</template>
<script>
export default {
    name: "rightEchart",
    components: {},
    data() {
        return {
            echart: null,
        };
    },
    props: {
        listAry: {
            type: Array,
            default() {
                return [];
            },
        },
    },
    watch: {
        listAry: {
            immediate: true,
            handler() {
                this.drawEchart();
            },
        },
    },
    created() {},
    mounted() {},
    methods: {
        drawEchart() {
            let xAxisData = [];
            let averageData = [];
            let circleData = [];
            this.listAry.map((item) => {
                xAxisData.push(item.SJ);
                let obj = {
                    name: item.SJ,
                    value: item.PJF,
                };
                averageData.push(obj);
                item.CHILDREN.map((itemInner) => {
                    circleData.push([item.SJ, itemInner]);
                });
            });
            let option = {
                tooltip: {
                    trigger: "axis",
                },
                grid: {
                    left: 50,
                    top: 50,
                    bottom: 50,
                    right: 50,
                },
                xAxis: [
                    {
                        type: "category",
                        data: xAxisData,
                        axisLine: {
                            lineStyle: {
                                color: "#999",
                            },
                        },
                        nameTextStyle: {
                            color: "#999",
                        },
                    },
                ],
                yAxis: [
                    {
                        name: "分",
                        type: "value",
                        min: "dataMin",
                        max: 100,
                        offset: 0,
                        // minInterval: 20,
                        splitLine: {
                            lineStyle: {
                                type: "solid",
                                color: "#DDD",
                            },
                        },
                        axisLine: {
                            show: false,
                        },
                        nameTextStyle: {
                            color: "#999",
                        },
                        splitArea: {
                            show: false,
                        },
                    },
                ],
                series: [
                    {
                        name: "平均分",
                        type: "line",
                        showAllSymbol: false,
                        symbol: "circle",
                        symbolSize: 10,
                        lineStyle: {
                            normal: {
                                color: "#EECB5F",
                                // shadowColor: 'rgba(0, 0, 0, .3)',
                                // shadowBlur: 0,
                                // shadowOffsetY: 5,
                                // shadowOffsetX: 5,
                            },
                        },
                        label: {
                            show: false,
                            position: "top",
                            textStyle: {
                                color: "#00A0A9",
                            },
                        },
                        itemStyle: {
                            color: "#00A0A9",
                            // borderColor: "#fff",
                            // borderWidth: 3,
                            // shadowColor: 'rgba(0, 0, 0, .3)',
                            // shadowBlur: 0,
                            // shadowOffsetY: 2,
                            // shadowOffsetX: 2,
                        },
                        data: averageData,
                    },
                    {
                        tooltip: {
                            show: false,
                        },
                        name: "",
                        type: "scatter",
                        symbol: "circle",
                        symbolSize: 10,
                        label: {
                            emphasis: {
                                show: true,
                                formatter: function (param) {
                                    return param.data[2];
                                },
                                position: "top",
                            },
                        },
                        itemStyle: {
                            normal: {
                                color: "#00A0A9",
                            },
                        },
                        data: circleData,
                    },
                ],
            };
            this.echart = this.$echarts.dispose(
                document.getElementById("rightEchart")
            );
            this.echart = this.$echarts.init(
                document.getElementById("rightEchart")
            );
            this.echart.setOption(option);
        },
    },
};
</script>
<style scoped lang="scss">
.echartPart {
    width: 100%;
    height: 100%;
    position: relative;
    .echart {
        width: 100%;
        height: 100%;
    }
    .noData {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}
</style>
