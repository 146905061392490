<template>
    <div class="mainCtn">
        <div class="tabPart" v-if="getShowTab()">
            <div
                class="tabItem pointer"
                v-for="(item, index) in stateList"
                :key="index"
                :class="[dataGetZtActiveNum == index ? 'tabItemActive' : '']"
                @click="stateChange(index)"
            >
                {{ item.name }}
            </div>
        </div>
        <!-- 调查和抽查页面 -->
        <dcAndccPart
            v-if="dataGetZtActiveNum == 0 || dataGetZtActiveNum == 1"
        ></dcAndccPart>
        <!-- 考评 -->
        <kpPart v-if="dataGetZtActiveNum == 2"></kpPart>
    </div>
</template>
<script>
import * as TYPES from "@store/modules/mutation-type.js";
import { mapActions, mapMutations, mapGetters } from "vuex";
import dcAndccPart from "./dcAndccPart/index.vue";
import kpPart from "./kpPart/index.vue";
export default {
    name: "",
    components: {
        dcAndccPart,
        kpPart,
    },
    data() {
        return {
            stateList: [
                {
                    name: "调查",
                },
                {
                    name: "抽查",
                },
                {
                    name: "考评",
                },
            ],
            roleId: localStorage.roleId.split(","), //登录人角色id
            glyId: window.GLY, //管理员 id
            dcyId: window.DCY, //调查人 id
            ccyId: window.CCY, //抽查人 id
            adminRoleState: false, //角色是否包含 管理员
            ccyRoleState: false, //角色是否包含 抽查员
            dcyRoleState: false, //角色是否包含 调查员
        };
    },
    computed: {
        ...mapGetters({
            dataGetZtActiveNum: TYPES.dataGetZtActiveNum, //0 调查 1 抽查
        }),
    },
    watch: {},
    created() {},
    mounted() {
        this.adminRoleState = this.roleId.includes(this.glyId);
        this.ccyRoleState = this.roleId.includes(this.ccyId);
        this.dcyRoleState = this.roleId.includes(this.dcyId);
    },
    methods: {
        ...mapActions([]),
        ...mapMutations({
            SET_DATAGETZTACTIVENUM: TYPES.SET_DATAGETZTACTIVENUM,
        }),
        // 是否展示顶部切换tab
        getShowTab() {
            // 管理员 ||( 调查员 + 抽查人)
            let state = false;
            if (
                this.adminRoleState ||
                (this.ccyRoleState && this.dcyRoleState)
            ) {
                state = true;
            }
            return state;
        },
        //管理员切换 抽查 调查
        stateChange(index) {
            this.SET_DATAGETZTACTIVENUM(index);
        },
    },
};
</script>
<style scoped lang="scss">
.mainCtn {
    width: calc(100% - 300px);
    margin: 20px 150px;
    .tabPart {
        width: 100%;
        border-bottom: 1px solid #b9e2e2;
        .tabItem {
            color: #02a355;
            font-size: 18px;
            margin-right: 10px;
            display: inline;
        }
        .tabItemActive {
            border-bottom: 1px solid #02a355;
        }
    }
}
</style>
